@import '../variables/index.less';

.content-space-between {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.regular {
  font-family: @RegularFont;
}
.medium {
  font-family: @MediumFont;
}
.bold {
  font-family: @BoldFont;
}

.fw-bold {
  font-weight: bold;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.roboto {
  font-family: Roboto;
}

.shadow {
  box-shadow: 0 3px 9px #00000029;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.prewrap {
  white-space: pre-wrap;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }

  &.center {
    justify-content: center;
    align-items: center;
  }

  &.end {
    justify-content: end;
    align-items: end;
  }

  &.align-items-center {
    align-items: center;
  }

  &.align-items-baseline {
    align-items: baseline;
  }

  &.align-items-start {
    align-items: flex-start;
  }

  &.align-items-end {
    align-items: flex-end;
  }

  &.justify-content-center {
    justify-content: center;
  }

  &.justify-content-start {
    justify-content: flex-start;
  }

  &.justify-content-space-between {
    justify-content: space-between;
  }

  &.justify-content-space-around {
    justify-content: space-around;
  }

  &.justify-content-space-evenly {
    justify-content: space-evenly;
  }

  &.justify-content-end {
    justify-content: flex-end;
  }

  &.flex-wrap {
    flex-wrap: wrap;
  }

  &.flex-1 {
    flex: 1;
  }
  .flex-2 {
    flex: 2;
  }
  .flex-3 {
    flex: 3;
  }
  .flex-basis-50 {
    flex-basis: 50%;
  }

  .flex-shrink-0 {
    flex-shrink: 0;
  }

  .flex-4 {
    flex: 4;
  }
  .flex-grow-1 {
    flex-grow: 1;
  }

  > .align-self-start {
    align-self: flex-start;
  }

  > .align-self-end {
    align-self: flex-end;
  }
}

.br2 {
  border-radius: 2px;
}

.br4 {
  border-radius: 4px;
}

.br8 {
  border-radius: 8px;
}

.text-end {
  text-align: end;
}

.text-justified {
  text-align: justify;
}

.word-break-break-all {
  word-break: break-all;
}

.word-break-break-word {
  word-break: break-word;
}

.tal {
  text-align: left;
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.white {
  color: @white;
}
.gray {
  color: @gray;
}

.red {
  color: @redLight;
}
.green {
  color: @green;
}

.black-heading {
  color: @darkBlue;
  font-size: 24px;
}

.bg-white {
  background-color: @white;
}
.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}
.stick-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.display-hidden {
  display: none;
}
.text-align-l {
  text-align: left;
}
.text-align-r {
  text-align: right;
}
.text-align-center {
  text-align: center;
}

.overflow {
  &.auto {
    overflow: auto;
  }
  &.hidden {
    overflow: hidden;
  }
}
.mx-width50 {
  max-width: 50%;
}
.full-width {
  width: 100%;

  &.i {
    width: 100% !important;
  }
}

.full-height {
  height: 100%;
  &.i {
    height: 100% !important;
  }
}

.h-screen {
  height: 100vh;
}

.h44 {
  height: 44px !important;
}

.height-auto {
  height: auto;
}

.width-auto {
  width: auto;
  &.i {
    width: auto !important;
  }
}
.z2 {
  z-index: 2;
}

.bg-silver {
  background-color: @silver;
}

.bg-gray-highlight {
  background: #eff2ff;
  //box-shadow: 0px 3px 6px #00000029;
}

.bg-dark-blue {
  background-color: @darkBlue;
}

.bg-light-blue {
  background-color: @calendarBlue;
}
.bg-slate-blue {
  background-color: #254d71;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

.clearfix {
  display: inline-block;
}

/* start commented backslash hack \*/

* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}

.vh100 {
  min-height: 100vh;
}

.vh80 {
  min-height: 80vh;
}

.vh50 {
  min-height: 50vh;
}

.h100 {
  height: 100%;
}
.vw100 {
  min-width: 100vh;
}

.mw-172 {
  min-width: 172px;
}

.w100 {
  width: 100%;
}
.w75 {
  width: 75%;
}

.w33 {
  width: 33.33%;
}

.h50 {
  height: 50%;
}

.w45 {
  width: 45%;
}

.w50 {
  width: 50%;
}

.w120p {
  width: 120px;
}

.w90 {
  width: 90%;
}

.w25 {
  width: 25%;
}

.wfc {
  width: fit-content;
}

.w100p {
  width: 100px;
}

.p2 {
  padding: 2px;
}

.p16 {
  padding: 16px;
}

.p12 {
  padding: 12px;
}

.pl0 {
  padding-left: 0px;
}

.pl4 {
  padding-left: 4px;
}

.pl5 {
  padding-left: 5px;
}

.pl6 {
  padding-left: 6px;
}

.pl8 {
  padding-left: 8px;
}

.pr8 {
  padding-right: 8px;
}

.pl10 {
  padding-left: 10px;
}
.pl14 {
  padding-left: 14px;
}
.pl16 {
  padding-left: 16px;
}

.pl20 {
  padding-left: 20px;
}

.pl24 {
  padding-left: 24px;
}

.pl25 {
  padding-left: 25px;
}

.pl28 {
  padding-left: 28px;
}

.pl29 {
  padding-left: 29px;
}

.pl30 {
  padding-left: 30px;
}

.pl40 {
  padding-left: 40px;
}

.pl48 {
  padding-left: 48px;
}

.pr0 {
  padding-right: 0px;
}

.pr4 {
  padding-right: 4px;
}
.pr5 {
  padding-right: 5px;
}

.pr10 {
  padding-right: 10px;
}
.pr14 {
  padding-right: 14px;
}
.pr16 {
  padding-right: 16px;
}

.pr18 {
  padding-right: 18px;
}

.pr20 {
  padding-right: 20px;
}

.pr24 {
  padding-right: 24px;
}

.pr28 {
  padding-right: 28px;
}

.pr29 {
  padding-right: 29px;
}

.pr30 {
  padding-right: 30px;
}

.pr32 {
  padding-right: 32px;
}

.pr96 {
  padding-right: 96px;
}

.pr40 {
  padding-right: 40px;
}

.pt0 {
  padding-top: 0px;
}

.pt2 {
  padding-top: 2px;
}

.pt4 {
  padding-top: 4px;
}

.pt8 {
  padding-top: 8px;
}

.pt10 {
  padding-top: 10px;
}

.pt12 {
  padding-top: 12px;
}

.pt16 {
  padding-top: 16px;
}

.pt20 {
  padding-top: 20px;
}

.pt28 {
  padding-top: 28px;
}

.pt30 {
  padding-top: 30px;
}

.pt48 {
  padding-top: 48px;
}

.pt60 {
  padding-top: 60px;
}

.pt66 {
  padding-top: 66px;
}

.pt100 {
  padding-top: 100px;
}

.pb0 {
  padding-bottom: 0;
}

.pb2 {
  padding-bottom: 2px;
}

.pb4 {
  padding-bottom: 4px;
}

.pb8 {
  padding-bottom: 8px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb12 {
  padding-bottom: 12px;
}

.pb16 {
  padding-bottom: 16px;
}

.pb18 {
  padding-bottom: 18px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb24 {
  padding-bottom: 24px;
}

.pb25 {
  padding-bottom: 25px;
}

.pb26 {
  padding-bottom: 26px;
}

.pb27 {
  padding-bottom: 27px;
}

.pb28 {
  padding-bottom: 28px;
}

.pb29 {
  padding-bottom: 29px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb32 {
  padding-bottom: 32px;
}

.pb64 {
  padding-bottom: 64px;
}

.p0 {
  padding: 0px !important;
}

.p5 {
  padding: 5px;
}

.p8 {
  padding: 8px !important;
}

.p9 {
  padding: 9px !important;
}

.p10 {
  padding: 10px !important;
}

.p16 {
  padding: 16px;
}

.p20 {
  padding: 20px;
}

.p24 {
  padding: 24px;
}

.p30 {
  padding: 30px;
}

.ml1 {
  margin-left: 1px;
}

.ml0 {
  margin-left: 0px;
}

.ml2 {
  margin-left: 2px;
}

.ml4 {
  margin-left: 4px;
}

.ml5 {
  margin-left: 5px;
}

.ml6 {
  margin-left: 6px;
}

.ml7 {
  margin-left: 7px;
}

.ml8 {
  margin-left: 8px;
}

.ml10 {
  margin-left: 10px;
}

.ml12 {
  margin-left: 12px;
}

.ml13 {
  margin-left: 13px;
}

.ml14 {
  margin-left: 14px;
}

.ml15 {
  margin-left: 15px !important;
}

.ml16 {
  margin-left: 16px;
}

.ml20 {
  margin-left: 20px;
}

.ml24 {
  margin-left: 24px;
}

.ml30 {
  margin-left: 30px;
}

.ml31 {
  margin-left: 31px;
}

.ml45 {
  margin-left: 45px;
}

.ml50 {
  margin-left: 50px;
}

.ml100 {
  margin-left: 100px;
}

.mr0 {
  margin-right: 0px;
}

.mr2 {
  margin-right: 2px;
}

.mr4 {
  margin-right: 4px;
}

.mr6 {
  margin-right: 6px;
}

.mr8 {
  margin-right: 10px;
}

.mr10 {
  margin-right: 10px;
}

.mr14 {
  margin-right: 14px;
}

.mr15 {
  margin-right: 15px;
}

.mr18 {
  margin-right: 18px;
}

.mr40 {
  margin-right: 40px;
}

.mr50 {
  margin-right: 50px;
}

.mt2 {
  margin-top: 2px;
}
.mt3 {
  margin-top: 3px;
}

.mt4 {
  margin-top: 4px;
}

.mt5 {
  margin-top: 5px;
}

.mt8 {
  margin-top: 8px;
}

.mt7 {
  margin-top: 7px;
}

.mt13 {
  margin-top: 13px;
}

.mt21 {
  margin-top: 21px;
}

.mt26 {
  margin-top: 26px;
}

.mt27 {
  margin-top: 27px;
}

.mt37 {
  margin-top: 37px;
}

.mb4 {
  margin-bottom: 4px;
}

.mb6 {
  margin-bottom: 6px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mr5 {
  margin-right: 5px;
}

.mr8 {
  margin-right: 8px;
}

.mr10 {
  margin-right: 10px;
}

.mr11 {
  margin-right: 11px;
}

.mr12 {
  margin-right: 12px;
}

.mr16 {
  margin-right: 16px;
}

.mr20 {
  margin-right: 20px;
}

.mr24 {
  margin-right: 24px;
}

.mr30 {
  margin-right: 30px;
}

.mr32 {
  margin-right: 32px;
}

.mr36 {
  margin-right: 36px;
}

.mr48 {
  margin-right: 48px;
}

.mr77 {
  margin-right: 77px;
}

.mt0 {
  margin-top: 0px;
}

.mt1 {
  margin-top: 1px;
}

.mt2 {
  margin-top: 2px;
}

.mt6 {
  margin-top: 6px;
}

.mt16 {
  margin-top: 16px;
}

.mt10 {
  margin-top: 10px;
}

.mt12 {
  margin-top: 12px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt24 {
  margin-top: 24px;
}

.mt30 {
  margin-top: 30px;
}

.mt32 {
  margin-top: 32px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mt56 {
  margin-top: 56px;
}

.mt60 {
  margin-top: 60px;
}

.mt90 {
  margin-top: 90px;
}

.mb0 {
  margin-bottom: 0;
}

.mb2 {
  margin-bottom: 2px;
}

.mb4 {
  margin-bottom: 4px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb26 {
  margin-bottom: 26px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb42 {
  margin-bottom: 42px;
}

.mb48 {
  margin-bottom: 48px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb180 {
  margin-bottom: 180px;
}

.mb210 {
  margin-bottom: 180px;
}

.m0 {
  margin: 0px;
}

.m10 {
  margin: 10px;
}
.m16 {
  margin: 16px;
}

.m18 {
  margin: 18px;
}

.m20 {
  margin: 20px;
}

.m30 {
  margin: 30px;
}

.border1 {
  border: 1px solid black !important;
}

.br2 {
  border-radius: 2px;
}

.br4 {
  border-radius: 4px;
}

.br5 {
  border-radius: 5px;
}

.br6 {
  border-radius: 6px;
}

.br8 {
  border-radius: 8px;
}

.br10 {
  border-radius: 10px;
}

.auto-fetch-input {
  height: 44px;
  //border: 1px solid #121432;
  border-radius: 4px;
  background: @white;
}

.mtn-20 {
  margin-top: -20px;
}

.heading-title {
  font-family: @MediumFont;
  font-size: 22px;
  padding-left: 16px;
  display: flex;
  width: 80%;
  overflow: hidden;
  padding-right: 16px;
  align-items: center;
}
.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-16 {
  font-size: 16px;
}
.font-15 {
  font-size: 15px;
}
.font-14 {
  font-size: 14px;
}
.font-18 {
  font-size: 18px;
}
.font-13 {
  font-size: 13px;
}
.font-12 {
  font-size: 12px;
}
.font-11 {
  font-size: 10px;
}
.font-10 {
  font-size: 10px;
}

.page-content-width {
  //width: calc(100vw - 48px);
}

.black {
  color: black;
}

.text-green {
  color: #008000;
}

.blue {
  color: @blue;
}

.danger {
  color: #a01717;
  font-family: @MediumFont;
  font-size: 12px;
}

.page-height {
  height: calc(100vh - 84px);
}

.pointer {
  cursor: pointer;
}
.not-allowed-cursor {
  cursor: not-allowed;
}
.cursor-auto {
  cursor: auto;
}
.pointer-none {
  pointer-events: none;
}
.default {
  cursor: default;
}
.move {
  cursor: move;
}
.showScrollbar {
  overflow-y: scroll;
}
.smooth-scroll {
  scroll-behavior: smooth;
}

.ellipsis-second-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.underline {
  text-decoration: underline;
}

.zast-select-dropdown {
  .rc-virtual-list-holder-inner > div:hover {
    background-color: @lightSilver;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: @white;
  }
}

.select-list-item-option-customers {
  .ant-select-item-option-content {
    white-space: pre-wrap !important;
    font-size: 12px !important;
  }
}

.approve-reject-btn {
  width: 80px !important;
  height: 28px !important;
  border-radius: 6px !important;
  border: none !important;
  font-size: 10px !important;
  font-family: 'Roboto-Bold' !important;
  color: #ffffff !important;
  line-height: 14px !important;
}

.approve-btn-color {
  background-color: #4eb39d !important;
}

.reject-btn-color {
  background-color: #cd6155 !important;
}
